/* src/ImageGallery.css */
@font-face {
  font-family: 'Araboto Bold';
  src: url('./fonts/ArabotoBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Araboto Light';
  src: url('./fonts/ArabotoLight.ttf') format('truetype');
  font-weight: Bold;
  font-style: normal;
}
.image-gallery {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.main-heading {
  color: #fbc604; /* Yellow color */
  font-family: 'Araboto Bold', sans-serif; /* Font-family for beautiful headings */
  font-size: 4rem; /* Adjust size as needed */
  margin-bottom: 10px;
  text-align: center;
}

.sub-heading {
  color: #fbc604; /* Yellow color */
  font-family: 'Araboto Bold', sans-serif; /* Font-family for beautiful headings */
  font-size: 4rem; /* Adjust size as needed */
  margin-bottom: 10px;
  text-align: center;
}

.image-gallery.loaded {
  opacity: 1;
}

.image-container {
  position: relative;
  margin: 10px;
  width: calc(25% - 20px);
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.image-container img {
  width: 100%;
  height: 100%;
  display: block;
}

.image-container .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: translateY(100%);
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.image-container:hover .overlay {
  opacity: 1;
  transform: translateY(0);
}

.image-container:hover {
  transform: scale(1.05);
}

.image-container .overlay p {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 768px) {
  .main-heading {

  font-size: 3.3rem; /* Adjust size as needed */
  }
  .sub-heading {
    margin-bottom: 5px;

    font-size: 3.3rem; /* Adjust size as needed */
  }
  .image-container {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .main-heading {
    font-size: 3rem; /* Adjust size as needed */
    }
    .sub-heading {
      margin-bottom: 5px;

      font-size: 3rem; /* Adjust size as needed */
    }
  .image-container {
    width: calc(50% - 20px);
  }
}
