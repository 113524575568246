@import url('https://fonts.googleapis.com/css2?family=Arboto:wght@400;700&display=swap');

@font-face {
  font-family: 'Araboto Bold';
  src: url('./fonts/ArabotoBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.home-section {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: transform 1s ease-in-out;
  padding-top: 80px;
  z-index: 1;
}

.slide {
  transform: translateX(100%);
}

.slide-left {
  transform: translateX(100%);
}

.slide-right {
  transform: translateX(-100%);
}

.home-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.text-content h1 {
  font-size: 3rem;
  font-weight: 700; /* Bold weight for the heading */
  font-family: 'Araboto Bold', sans-serif; /* Font-family for beautiful headings */
  color: #fbc604;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.text-content p {
  font-size: 1.5rem;
  font-weight: 400; /* Regular weight for the content */
  font-family: 'Arboto', sans-serif;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

@media (prefers-reduced-motion: reduce) {
  .home-section {
    transition: none;
  }
}

.slider-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.text-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {
  .text-content h1 {
    font-size: 3rem;
  }
  .text-content p {
    font-size: 1.65rem;
  }
  .home-section {
    padding-top: 50px;
  }
}

@media (max-width: 480px) {
  .text-content h1 {
    font-size: 2rem;
  }
  .text-content p {
    font-size: 1.25rem;
  }
  .home-section {
    padding-top: 30px;
  }
}
