.videography-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.video-card {
  position: relative;
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  background-color: black;
  color: white;
  text-align: left;
  overflow: hidden;
  transition: transform 0.3s;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
}

.video-card:hover {
  transform: scale(1.05);
}

.video-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  transition: background-color 0.3s;
  color: #fbc604; /* Set the logo color to yellow */
}

.video-card:hover .video-icon {
  background-color: transparent;
}

.video-text {
  padding: 10px 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  position: relative;
  z-index: 2;
}

.video-text p {
  text-decoration: underline;
  margin: 10px 0;
}
.videos-heading{
  color: #fbc604; /* Yellow color */
  font-family: 'Araboto Bold', sans-serif; /* Font-family for beautiful headings */
  font-size: 4rem; /* Adjust size as needed */
  margin-bottom: 10px;
  text-align: center;
}
.video-text button {
  background: none;
  border: none;
  color: #fbc604;
  font-size: 16px;
  cursor: pointer;
  /* text-decoration: underline; */
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  position: relative;
  z-index: 2;
}

.video-text button span {
  margin-left: 5px;
}

.video-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.video-card:hover .video-image {
  opacity: 0.8;
}

.video-card:hover .video-overlay {
  opacity: 1;
}

@media (max-width: 768px) {
  .videos-heading{
    margin-bottom: 5px;
    font-size: 3.3rem; /* Adjust size as needed */
  }
  
  .video-card {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .videos-heading{
    margin-bottom: 5px;
    font-size: 3rem; /* Adjust size as needed */
  }
  .video-card {
    width: 100%;
  }
}
