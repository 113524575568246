.header {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.0); /* 80% transparent */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 40px; /* Increase padding for a more spacious layout */
}

.navbar-left {
  flex: 1;
}

.navbar-brand .logo {
  height: 180px; /* Make the logo bigger */
}

.navbar-center {
  flex: 2;
  display: flex;
  justify-content: flex-end; /* Align items to the right */
}

/* Hamburger menu button */
.menu-toggle {
  display: none; /* Hide by default */
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: relative;
  z-index: 10;
}

/* Hamburger lines */
.menu-icon, .menu-icon::before, .menu-icon::after {
  content: '';
  display: block;
  width: 30px; /* Adjust width */
  height: 3px; /* Adjust height */
  background-color: black;
  transition: all 0.3s ease;
  position: absolute;
}

/* Line positions */
.menu-icon {
  top: 0;
}

.menu-icon::before {
  top: 10px; /* Adjust spacing */
}

.menu-icon::after {
  top: 20px; /* Adjust spacing */
}

/* Cross effect */
.menu-toggle.open .menu-icon {
  transform: rotate(45deg);
}

.menu-toggle.open .menu-icon::before {
  transform: rotate(90deg);
  top: 0;
}

.menu-toggle.open .menu-icon::after {
  transform: rotate(90deg);
  top: 0;
}

/* Navbar menu styling */
.navbar-menu {
  display: flex;
  gap: 20px;
}

/* Active state for mobile menu */
.navbar-menu.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust background color */
  z-index: 9;
  padding: 10px 0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
}

.nav-link {
  text-decoration: none;
  color: #f7f7f7; /* Change to desired color */
  padding: 15px 20px;
  font-size: 22px; /* Make the buttons larger */
  font-weight: bold; /* Make the buttons bold */
  transition: color 0.3s, background-color 0.3s; /* Smooth transition for hover effect */
}

.nav-link:hover {
  color: #fbc604; /* Change text color on hover */
  text-decoration: underline; /* Underline text on hover */
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .navbar-left {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .navbar-center {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .menu-toggle {
    display: block; /* Show the menu toggle button on small screens */
    margin-right: 20px; /* Add margin to separate from the edge */
  }

  .navbar-menu {
    display: none; /* Initially hide the menu items */
    flex-direction: column;
    gap: 10px;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 100%;
    right: 0; /* Align menu to the right edge */
    background-color: rgba(0, 0, 0, 0.8); /* Adjust background color to blackish */
    z-index: 9;
    padding: 10px 0;
    transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
  }

  .navbar-menu.active {
    background-color: #ffffff; /* Fully white background color when active */
    transform: translateY(0); /* Slide menu into view */
  }

  .nav-link {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    color: #fbc604; /* Desired color for buttons */
    font-weight: bold; /* Make the buttons bold */
    font-size: 18px; /* Increase size */
    text-decoration: none; /* Remove underline by default */
    transition: color 0.3s, text-decoration 0.3s; /* Smooth transition for hover effect */
  }

  .nav-link:hover {
    color: #959966; /* Change color on hover */
    text-decoration: underline; /* Underline text on hover */
  }
}
