:root {
  --background-color: transparent;
  --text-color: hsl(0, 0%, 100%);
  --main-heading-color: #fff;
  --sub-heading-color: #ffcc00;
}
@font-face {
  font-family: 'Araboto Bold';
  src: url('./fonts/ArabotoBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.about-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.about-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  flex: 1 0 auto;
}

.about-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px;
}

.wrapper {
  display: grid;
  place-content: center;
  background-color: var(--background-color);
  font-family: "Oswald", sans-serif;
  font-size: clamp(1.5rem, 1rem + 8vw, 10rem);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-color);
  position: absolute;
  top: 20px;
  left: 20px;
}

.wrapper > div {
  grid-area: 1/1/-1/-1;
}

.top {
  clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
}

.bottom {
  clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
  color: transparent;
  background: -webkit-linear-gradient(177deg, black 53%, var(--text-color) 65%);
  background: linear-gradient(177deg, black 53%, var(--text-color) 65%);
  background-clip: text;
  -webkit-background-clip: text;
  transform: translateX(-0.02em);
}

.about-content {
  width: 50vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(10px);
  position: absolute;
  right: 0;
  top: 0;
  padding: 40px;
  color: var(--text-color);
}

.about-text {
  text-align: left;
}

.about-text h2 {
  font-size: 3rem;
  font-family: 'Araboto Bold', sans-serif;
  text-align: center;

  margin-bottom: 30px;
  color: var(--main-heading-color);
}

.point {
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(50px);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.point h3 {
  font-size: 2rem;
  text-align: left;

  font-family: 'Mislab', sans-serif;
  margin-top: 0;
  color: var(--sub-heading-color);
  display: flex;
  align-items: center;
}

.point .icon {
  margin-right: 10px;
}

.point p {
  font-size: 1.25rem;
  font-family: 'Mislab', sans-serif;
  margin-top: 10px;
  text-align: left;
}

.animate-subheading {
  opacity: 1;
  transform: translateY(0);
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .wrapper {
    font-size: clamp(3rem, 1.5rem + 5vw, 6rem);
    width: 100%;
    text-align: center;
    top: 10%;
    left: 0;
  }
  
  .about-container {
    flex-direction: column;
  }

  .about-image {
    height: 50vh;
  }

  .about-content {
    width: 100vw;
    height: 80vh;
    position: relative;
    top: auto;
    right: auto;
    border-radius: 0;
    box-shadow: none;
    backdrop-filter: none;
    padding: 20px;
    margin-top: 420px;
    display: flex;
    justify-content: center;
  }

  .about-overlay {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  .about-text h2 {
    font-size: 4.5rem;
    margin-top: 20px;
  }

  .point h3 {
    text-align: left;
    font-size: 2rem;
  }

  .point p {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .wrapper {
    font-size: clamp(4.5rem, 1rem + 5vw, 4rem);
    top: 20%;
    width: 100%;
    text-align: center;
  }

  .about-overlay {
    padding: 20px;
  }

  .about-content {
    padding: 20px;
    height: 50vh;
    display: flex;
    justify-content: center;
  }

  .about-text h2 {
    font-size: 2rem;
    margin-top: 40px;
  }

  .point h3 {
    font-size: 1.75rem;
  }

  .point p {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-heading {
  animation: slideInFromTop 1s ease-out forwards;
}



.point:nth-child(1).animate-subheading {
  animation: slideInFromBottom 60s ease-out forwards;
  
}

.point:nth-child(2).animate-subheading {
  animation: slideInFromBottom 0.4s ease-out forwards;
  
}

.point:nth-child(3).animate-subheading {
  animation: slideInFromBottom 0.2s ease-out forwards;
  
}
