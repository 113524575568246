/* src/styles/fonts.css */
@font-face {
    font-family: 'Araboto Bold';
    src: url('./fonts/ArabotoBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


.clients-section {
    text-align: center;
    padding: 2rem;
    background-color: #f0f0f0;
}

.clients-carousel {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.clients-wrapper {
    display: flex;
    transition: transform 0.5s ease;
    width: calc(100% + 30px); /* 3 clients at a time plus some margin */
}

.client {
    flex: 0 0 33.333%; /* Show 3 clients at a time */
    padding: 0 1.5%;
    box-sizing: border-box;
}
.clients-heading {
    color: #fbc604; /* Yellow color */
    font-family: 'Araboto Bold', sans-serif; /* Font-family for beautiful headings */
    font-size: 4rem; /* Adjust size as needed */
    margin-bottom: 10px;
    text-align: center;
}

.client img {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 200px; /* Increase size */
    height: 200px; /* Increase size */
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.dots-container {
    margin-top: 1rem;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
}

.dot.active {
    background-color: #717171;
}

.client img:hover {
    transform: scale(1.1);
}
@media (max-width: 1024px) {
    .clients-heading {
        
       
        font-size: 3.7rem; /* Adjust size as needed */
        
    }
    .clients-wrapper {
        width: calc(100% * 2); /* Show 2 clients at a time */
    }

    .client {
        flex: 0 0 50%;
        padding: 0 2%; /* Adjust spacing for 2 clients */
    }

    .client img {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 768px) {
    .clients-heading {
        
       
        font-size: 3.3rem; /* Adjust size as needed */
        
    }
    .clients-wrapper {
        width: 100%;
    }
    .client {
        flex: 0 0 50%; /* Show 2 clients at a time on medium screens */
        padding: 0 2%; /* Adjust spacing for 2 clients */
    }

    .client img {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 480px) {
    
    .clients-heading {
        font-size: 3rem; /* Adjust size as needed */
        
    }
    .client {
        flex: 0 0 50%; /* Show 1 client at a time on small screens */
        padding: 0 2%; /* Adjust spacing for 1 client */
    }
    .client img {
        width: 120px;
        height: 120px;
    }

    .dot {
        height: 8px;
        width: 8px;
    }
}
